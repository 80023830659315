import { DateTime } from 'luxon';
import React, { ReactElement, ReactNode } from 'react';
import { FormCheck } from 'react-bootstrap';
import {
  CheckBoxField,
  HeaderDisplayKeysWithType,
  OverflowWithTooltip,
  TeeSheetWithButton,
  TextField,
} from '../../components';
import { PHITee, TeeInspection, SimpleStudent } from '../../model';
import { Nullable } from '../../types';
import {
  BasePHITee,
  DefaultTeeContext,
  ExtractedPHITee,
  TEE_CONTEXT_MAP,
  TEE_CONTEXT_SPECIAL_MAP,
  TEE_CONTEXT_SPECIAL_TEE,
  TeeInitialExaminationEnum,
  teeTransformFirstExaminationField,
  teeTransformFollowUpExaminationField,
  teeTransformSingleExaminationField,
  serializeTeeSheet,
} from '../../utils/tee';
import createCalculator from 'final-form-calculate';

type TeePageData = SimpleStudent & Nullable<ExtractedPHITee>;

// export function serializeTeeSheet(v: PHITee[keyof PHITee]): string {
//   if (v && typeof v === 'object' && !(v instanceof DateTime)) {
//     return Object.entries(v)
//       .map(([k, v]) => `${k}${v}`)
//       .join(',');
//   }
//   return '';
// }

// export function deserializeTeeSheet(value: string): Record<string, string> {
//   return value
//     .split(',')
//     .map((s) => s.trim())
//     .reduce((des, str) => {
//       const key = str.slice(0, 2);
//       let value = str.slice(2);
//       // 1X,2X,3X,4X is adult teeth, convert to uppercase
//       // 5X,6X,7X,8X is child teeth, convert to lowercase
//       if (key[0] >= '5') value = value.toLowerCase();
//       else value = value.toUpperCase();
//       des[key] = value;
//       return des;
//     }, {} as Record<string, string>);
// }

function wrapOverflow(content: string) {
  return (
    <OverflowWithTooltip forceToolTip={content?.length > 7}>
      {content}
    </OverflowWithTooltip>
  );
}

const transformCheckedBox = (
  property: string,
  v: unknown,
  i: number,
  e?: boolean
): ReactElement =>
  e ? (
    <CheckBoxField property={property} />
  ) : (
    <FormCheck checked={!!v} readOnly type="checkbox" />
  );

const transformTextInput = (
  property: string,
  v: unknown,
  i: number,
  e?: boolean
) => (e ? <TextField property={property} /> : wrapOverflow(v as string));

export const TeeTableHeader: HeaderDisplayKeysWithType<BasePHITee>[] = [
  {
    property: 'tee',
    display: '實施否',
    onRender: transformCheckedBox.bind(null, 'tee'),
  },
  {
    property: 't01',
    display: '未治療齲齒',
    onRender: teeTransformFirstExaminationField.bind(null, 't01'),
  },
  {
    property: 't11',
    display: '已治療齲齒',
    onRender: teeTransformSingleExaminationField.bind(null, 't11'),
  },
  {
    property: 't12',
    display: (
      <React.Fragment>
        上顎恆牙
        <br />
        第一大臼齒齲齒經驗
      </React.Fragment>
    ),
    onRender: teeTransformSingleExaminationField.bind(null, 't12'),
  },
  {
    property: 't13',
    display: (
      <React.Fragment>
        下顎恆牙
        <br />
        第一大臼齒齲齒經驗
      </React.Fragment>
    ),
    onRender: teeTransformSingleExaminationField.bind(null, 't13'),
  },
  {
    property: 't15',
    display: (
      <React.Fragment>
        恆牙臼齒
        <br />
        窩溝封填
      </React.Fragment>
    ),
    onRender: teeTransformSingleExaminationField.bind(null, 't15'),
  },
  {
    property: 't04',
    display: '口腔衛生不良',
    onRender: teeTransformFirstExaminationField.bind(null, 't04'),
  },
  {
    property: 't07',
    display: '咬合不正',
    onRender: teeTransformFirstExaminationField.bind(null, 't07'),
  },
  {
    property: 't05',
    display: '牙結石',
    onRender: teeTransformFirstExaminationField.bind(null, 't05'),
  },
  {
    property: 't08',
    display: '牙齦炎',
    onRender: teeTransformFirstExaminationField.bind(null, 't08'),
  },
  {
    property: 't09',
    display: '口腔黏膜異常',
    onRender: teeTransformFirstExaminationField.bind(null, 't09'),
  },
  {
    property: 't16',
    display: '牙周病',
    onRender: teeTransformFirstExaminationField.bind(null, 't16'),
  },
  {
    property: 't17',
    display: '乳牙待拔牙',
    onRender: teeTransformFirstExaminationField.bind(null, 't17'),
  },
  {
    property: 't03',
    display: '待拔牙',
    onRender: teeTransformFirstExaminationField.bind(null, 't03'),
  },
  {
    property: 't18',
    display: '贅生牙',
    onRender: teeTransformFirstExaminationField.bind(null, 't18'),
  },
  {
    property: 't02',
    display: '缺牙',
    onRender: teeTransformFirstExaminationField.bind(null, 't02'),
  },
  {
    property: 't19',
    display: '阻生牙',
    onRender: teeTransformFirstExaminationField.bind(null, 't19'),
  },
  {
    property: 't99',
    display: '口腔其他',
    onRender: teeTransformFirstExaminationField.bind(null, 't99'),
  },
  {
    property: 't99state',
    display: '口腔其他描述',
    style: { maxWidth: '100px' },
    onRender: transformTextInput.bind(null, 't99state'),
  },
  {
    property: 'tSheet',
    display: '口檢表',
    onRender: function teeSheetRender(v, i, e, c) {
      if (e) {
        const {
          [TEE_CONTEXT_MAP]: valueMap,
          [TEE_CONTEXT_SPECIAL_MAP]: specialValueMap,
          [TEE_CONTEXT_SPECIAL_TEE]: specialTee,
        } = (c?.context as typeof DefaultTeeContext) ?? DefaultTeeContext;
        return (
          <TeeSheetWithButton
            specialTooth={specialTee}
            sheetMap={valueMap}
            specialMap={specialValueMap}
          />
        );
      }
      const s = serializeTeeSheet(v);
      return wrapOverflow(s);
    },
  },
];

export const TeeFollowUpTableHeader: HeaderDisplayKeysWithType<ExtractedPHITee>[] =
  [
    {
      property: 'tee',
      display: '實施否',
      onRender: transformCheckedBox.bind(null, 'tee'),
    },
    {
      property: 'ret01',
      display: '未治療齲齒',
      onRender: teeTransformFollowUpExaminationField.bind(null, 't01', 'ret01'),
    },
    {
      property: 'ret04',
      display: '口腔衛生不良',
      onRender: teeTransformFollowUpExaminationField.bind(null, 't04', 'ret04'),
    },
    {
      property: 'ret07',
      display: '咬合不正',
      onRender: teeTransformFollowUpExaminationField.bind(null, 't07', 'ret07'),
    },
    {
      property: 'ret05',
      display: '牙結石',
      onRender: teeTransformFollowUpExaminationField.bind(null, 't05', 'ret05'),
    },
    {
      property: 'ret08',
      display: '牙齦炎',
      onRender: teeTransformFollowUpExaminationField.bind(null, 't08', 'ret08'),
    },
    {
      property: 'ret09',
      display: '口腔黏膜異常',
      onRender: teeTransformFollowUpExaminationField.bind(null, 't09', 'ret09'),
    },
    {
      property: 'ret16',
      display: '牙周病',
      onRender: teeTransformFollowUpExaminationField.bind(null, 't16', 'ret16'),
    },
    {
      property: 'ret17',
      display: '乳牙待拔牙',
      onRender: teeTransformFollowUpExaminationField.bind(null, 't17', 'ret17'),
    },
    {
      property: 'ret03',
      display: '待拔牙',
      onRender: teeTransformFollowUpExaminationField.bind(null, 't03', 'ret03'),
    },
    {
      property: 'ret18',
      display: '贅生牙',
      onRender: teeTransformFollowUpExaminationField.bind(null, 't18', 'ret18'),
    },
    {
      property: 'ret02',
      display: '缺牙',
      onRender: teeTransformFollowUpExaminationField.bind(null, 't02', 'ret02'),
    },
    {
      property: 'ret19',
      display: '阻生牙',
      onRender: teeTransformFollowUpExaminationField.bind(null, 't19', 'ret19'),
    },
    {
      property: 'ret99',
      display: '口腔其他',
      onRender: teeTransformFollowUpExaminationField.bind(null, 't99', 'ret99'),
    },
    {
      property: 'reT99state',
      display: '複檢口腔其他描述',
      onRender: transformTextInput.bind(null, 't99state'),
    },
    {
      property: 'reTSheet',
      display: '複檢口檢表',
      onRender: function teeSheetRender(v, i, e, c) {
        if (e) {
          const {
            [TEE_CONTEXT_MAP]: valueMap,
            [TEE_CONTEXT_SPECIAL_MAP]: specialValueMap,
            [TEE_CONTEXT_SPECIAL_TEE]: specialTee,
          } = (c?.context as typeof DefaultTeeContext) ?? DefaultTeeContext;
          return (
            <TeeSheetWithButton
              specialTooth={specialTee}
              sheetMap={valueMap}
              specialMap={specialValueMap}
            />
          );
        }
        return wrapOverflow(serializeTeeSheet(v));
      },
    },
  ];

export const PHITeeCalculators = [
  createCalculator<TeePageData>(
    {
      field: /tSheet/,
      updates: {
        t03: (value: number, allValues: TeePageData | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (tSheetString.includes('E') || tSheetString.includes('e')) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          }
          return allValues?.t03;
        },
        t02: (value: number, allValues: TeePageData | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (tSheetString.includes('M') || tSheetString.includes('m')) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          }
          return allValues?.t02;
        },
        t12: (value: boolean, allValues: TeePageData | undefined) => {
          const t12Code = 'DEFdef';
          const tSheet = allValues?.tSheet;
          if (
            tSheet &&
            (t12Code.includes(tSheet['16']) || t12Code.includes(tSheet['26']))
          ) {
            return TeeInspection.Tested;
          }
          return allValues?.t12;
        },
        t13: (value: boolean, allValues: TeePageData | undefined) => {
          const t12Code = 'DEFdef';
          const tSheet = allValues?.tSheet;
          if (
            tSheet &&
            (t12Code.includes(tSheet['36']) || t12Code.includes(tSheet['46']))
          ) {
            return TeeInspection.Tested;
          }
          return allValues?.t13;
        },
        t01: (value: number, allValues: TeePageData | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (
            tSheetString &&
            (tSheetString.includes('D') ||
              tSheetString.includes('d') ||
              tSheetString.includes('e') ||
              tSheetString.includes('E'))
          ) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          } else if (tSheetString.includes('F') || tSheetString.includes('f')) {
            return TeeInitialExaminationEnum.NoProblem;
          }
          return allValues?.t01;
        },
        t11: (value: number, allValues: TeePageData | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (
            tSheetString &&
            (tSheetString.includes('D') ||
              tSheetString.includes('d') ||
              tSheetString.includes('e') ||
              tSheetString.includes('E'))
          ) {
            return TeeInspection.None;
          } else if (tSheetString.includes('F') || tSheetString.includes('f')) {
            return TeeInspection.Tested;
          }
          return allValues?.t11;
        },
        t15: (value: number, allValues: TeePageData | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (tSheetString.includes('SF') || tSheetString.includes('sf')) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          }
          return allValues?.t15;
        },
        t17: (value: number, allValues: TeePageData | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (tSheetString.includes('H') || tSheetString.includes('h')) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          }
          return allValues?.t17;
        },
        t18: (value: number, allValues: TeePageData | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (tSheetString.includes('S') || tSheetString.includes('s')) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          }
          return allValues?.t18;
        },
        t19: (value: number, allValues: TeePageData | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (tSheetString.includes('G') || tSheetString.includes('g')) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          }
          return allValues?.t19;
        },
      },
    },
    {
      field: /t01/,
      updates: {
        t11: (value: number, allValues: TeePageData | undefined) => {
          if (allValues?.t01 == TeeInitialExaminationEnum.NoExamined) {
            return TeeInspection.NoInspection;
          } else if (
            allValues?.t01 == TeeInitialExaminationEnum.InitialAbnoraml
          ) {
            return TeeInspection.None;
          }
          return allValues?.t11;
        },
      },
    },
    {
      field: /t99state/,
      updates: {
        t99: (value: number, allValues: TeePageData | undefined) => {
          if (allValues?.t99state) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          }
          return allValues?.t99;
        },
      },
    }
  ),
];

export const ExtractedPHITeeCalculators = [
  createCalculator<ExtractedPHITee>(
    {
      field: /tSheet/,
      updates: {
        t03: (value: number, allValues: ExtractedPHITee | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (tSheetString.includes('E') || tSheetString.includes('e')) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          }
          return allValues?.t03;
        },
        t02: (value: number, allValues: ExtractedPHITee | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (tSheetString.includes('M') || tSheetString.includes('m')) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          }
          return allValues?.t02;
        },
        t12: (value: boolean, allValues: ExtractedPHITee | undefined) => {
          const t12Code = 'DEFdef';
          const tSheet = allValues?.tSheet;
          if (
            tSheet &&
            (t12Code.includes(tSheet['16']) || t12Code.includes(tSheet['26']))
          ) {
            return TeeInspection.Tested;
          }
          return allValues?.t12;
        },
        t13: (value: boolean, allValues: ExtractedPHITee | undefined) => {
          const t12Code = 'DEFdef';
          const tSheet = allValues?.tSheet;
          if (
            tSheet &&
            (t12Code.includes(tSheet['36']) || t12Code.includes(tSheet['46']))
          ) {
            return TeeInspection.Tested;
          }
          return allValues?.t13;
        },
        t01: (value: number, allValues: ExtractedPHITee | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (
            tSheetString &&
            (tSheetString.includes('D') ||
              tSheetString.includes('d') ||
              tSheetString.includes('e') ||
              tSheetString.includes('E'))
          ) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          } else if (tSheetString.includes('F') || tSheetString.includes('f')) {
            return TeeInitialExaminationEnum.NoProblem;
          }
          return allValues?.t01;
        },
        t11: (value: number, allValues: ExtractedPHITee | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (
            tSheetString &&
            (tSheetString.includes('D') ||
              tSheetString.includes('d') ||
              tSheetString.includes('e') ||
              tSheetString.includes('E'))
          ) {
            return TeeInspection.None;
          } else if (tSheetString.includes('F') || tSheetString.includes('f')) {
            return TeeInspection.Tested;
          }
          return allValues?.t11;
        },
        t15: (value: number, allValues: ExtractedPHITee | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (tSheetString.includes('SF') || tSheetString.includes('sf')) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          }
          return allValues?.t15;
        },
        t17: (value: number, allValues: ExtractedPHITee | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (tSheetString.includes('H') || tSheetString.includes('h')) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          }
          return allValues?.t17;
        },
        t18: (value: number, allValues: ExtractedPHITee | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (tSheetString.includes('S') || tSheetString.includes('s')) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          }
          return allValues?.t18;
        },
        t19: (value: number, allValues: ExtractedPHITee | undefined) => {
          const tSheetString = serializeTeeSheet(allValues?.tSheet);
          if (tSheetString.includes('G') || tSheetString.includes('g')) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          }
          return allValues?.t19;
        },
      },
    },
    {
      field: /t01/,
      updates: {
        t11: (value: number, allValues: ExtractedPHITee | undefined) => {
          if (allValues?.t01 == TeeInitialExaminationEnum.NoExamined) {
            return TeeInspection.NoInspection;
          } else if (
            allValues?.t01 == TeeInitialExaminationEnum.InitialAbnoraml
          ) {
            return TeeInspection.None;
          }
          return allValues?.t11;
        },
      },
    },
    {
      field: /t99state/,
      updates: {
        t99: (value: number, allValues: ExtractedPHITee | undefined) => {
          if (allValues?.t99state) {
            return TeeInitialExaminationEnum.InitialAbnoraml;
          }
          return allValues?.t99;
        },
      },
    }
  ),
];
